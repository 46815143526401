<template>
  <div class="Repetition">
    <div @mouseover="hasHover = !hasHover" class="icon">

      <LifeskillNumbershapeWithProgress
        slot="center"
        :lifeskill-id="repetition.ls"
        :reversed="true"
        class="sunNumbershape"
        :play="hasHover" />

      <div class="titles-wrapper">
        <h5 v-if="repetition.app === 'memomaps-step-group'">
          {{ $t(`dashboard.general.lifeskillLabels.${repetition.ls}`) }}
        </h5>
        <h5 v-if="repetition.app.startsWith('language')">
          {{ repetition.subtitle }}
        </h5>
        <h4>{{ repetition.title }}</h4>
      </div>
    </div>

    <div class="repeats-wrapper">
      <div
        v-for="(repeat, index) of repetition.repeats"
        class="repeat"
        :data-when="repeat.when"
        :key="repeat.when">
        
        <img
          v-if="repeat.done"
          src="./empty-crystal.svg"
          :alt="index + 1 + ' repetition'"
          class="icon">

        <div v-if="!repeat.done">
          {{ repetitionTimeLabels[index] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LifeskillNumbershapeWithProgress from '@/modules/dashboard/components/InterestsRow/LifeskillNumbershapeWithProgress';
import categoryThemes from '@/category-themes';
import { getCategoryIdFromLifeskillId } from '@/utils';
import { mapGetters } from 'vuex';

export default {
  components: {
    LifeskillNumbershapeWithProgress
  },
  props: {
    repetition: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      repetitionTimeLabels: ['1D', '7D', '1M', '3M', '6M'],
      hasHover: false,
    }
  },
  computed: {
    ...mapGetters({
        getJourneyById: 'moduleCourse/getJourneyById',
    }),
    theme(){
      return categoryThemes[getCategoryIdFromLifeskillId(this.repetition.courseId)];
    } 
  },
  provide() {
     return {
         theme: this.theme
     };
  },
  mounted(){
  }

}
</script>

<style lang="scss" scoped>
.Repetition {
  display: flex;
  flex-direction: row;
  flex: 1;
  background: rgba(34, 33, 94, .3);

  @media (max-width: 650px) {
    flex-direction: column;

    .repeats-wrapper {
      padding-top: 1em;
      justify-content: space-between!important;
      margin-left: inherit!important;
      padding-left: 0.4em;
      padding-right: 0.4em;
      
    }
  }

  .icon {
    display: flex;
  }

  .sunNumbershape {
    $size: 5em;
    width: $size;
    height: $size;
    margin-right: 1em;
}

  .TeamChallengeJourneyIcon {
    width: 50px;
    height: 50px;
  }

  .titles-wrapper {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;


    h5 {
      font-size: 14px;
      color: #6163b1;
      margin: 0;
      font-weight: normal;
      text-transform: capitalize;
    }

    h4 {
      color: #e9e9e9;
      font-size: 20px;
      margin: 0;
      margin-top: 0.3em;
      font-weight: 500;
    }
  }

  .repeats-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;

    .repeat {
      margin: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #262065;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8em;
    }
  }

}
</style>

