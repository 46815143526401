<template>
  <div class="Aura">
    <div class="meter">
      <RiveAura
        class="riveAura"
        v-if="true"
        :energy-level="energyLevel" />
    </div>
    <div class="info">
      <div class="energy">
        <h2>{{ $t('title') }}</h2>
        <div v-if="energyLevel === 'green'">
          {{ $t('green') }}
        </div>
        <div v-if="energyLevel === 'yellow'">
          {{ $t('yellow') }}
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
        <div v-if="energyLevel === 'orange'">
          {{ $t('orange') }}
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
        <div v-if="energyLevel === 'red'">
          {{ $t('red') }}
          {{ $tc('repsLeft', repsLeft, { repsLeft }) }}
        </div>
      </div>
      <div class="neurons">
        <div
          :key="n"
          v-for="n in range(dailyGoal)"
          class="neuron"
          :class="{completed:completed(n)}">
          <img 
            v-if="completed(n)"
            class="completedNeuronImage"
            :src="neuronCompleted"
            :alt="n">

          <img 
            v-if="!completed(n)"
            class="emptyNeuronImage"
            :src="neuronNotCompleted">
        </div>
      </div>
    </div>
  </div>
</template>

<translations>
  title: AURA
  title_no: AURA

  green: Your aura is crystal clear!
  green_no: Auraen din er krystallklar!

  yellow: Your aura is weakening!
  yellow_no: Auraen din er uklar!

  orange: Your aura is fading!
  orange_no: Auraen din blir svakere!

  red: Your aura is disappearing!
  red_no: Auraen din forsvinner!

  repsLeft: Light up {repsLeft} neuron to save your memory. | Light up {repsLeft} neurons to save your memory.
  repsLeft_no: Lys opp {repsLeft} nevron for å redde hukommelsen. | Lys opp {repsLeft} nevroner for å redde hukommelsen.

  repetitions: Repetitions
  repetitions_no: Repetisjoner
</translations>

<script>
import { mapGetters } from 'vuex';
import RiveAura from './RiveAura';
import neuronCompleted from './neuron-completed.svg';
import neuronNotCompleted from './neuron-not-completed.svg';

export default {
  components: { RiveAura },
  
  computed: {
    ...mapGetters({
      repetitions: 'repetition/all',
      yellow: 'repetition/yellow',
      red: 'repetition/red',
      due: 'repetition/due',
      today: 'repetition/today',
      energyLevel: 'repetition/energyLevel',
      energyFull: 'repetition/energyFull',
      energyLow: 'repetition/energyLow',
      energyDown: 'repetition/energyDown',
      energyBringUp: 'repetition/energyBringUp',
      repsLeft: 'repetition/repsLeft',
      dailyGoal: 'repetition/dailyGoal',
    }),

    neuronCompleted() {
      return neuronCompleted;
    },
    neuronNotCompleted() {
      return neuronNotCompleted;
    },

  },

  methods: {
    completed(n) {
      return n <= this.dailyGoal - this.repsLeft;
    },

    range(n) {
      return Array(n).fill(undefined).map((_, idx) => (idx + 1));
    }
  },
}
</script>

<style lang="scss" scoped>
.popup {
    flex-direction: column;
    align-items: center!important;

    .info .energy {
      display: none;
    }

}
.Aura {
  display: flex;
  justify-content: space-between;
  height: 14em;
  margin: auto;
  align-items: stretch;

  .meter {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.75);
    padding: 1em;
    padding-left: 0em;
    .energy {
      width: 17em;
      @media (max-width: 620px) {
        width: 11em;
      }
    }
  }

  .neurons {
    padding-top: 1em;
    display: flex;
    @media (max-width: 620px) {
      padding-top: 1.5em;
    }
  }

  .neuron {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: #102f63;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    &.completed {
      box-shadow: 0px 0px 16px 4px #40D4F41F;
      background-color: #2478bd62;
    }
    @media (max-width: 620px) {
      width: 9vw;
      height: 9vw;
      margin-right: 3vw;
    }
  }
  .completedNeuronImage {
      width: 35px;
      height: 35px;
      @media (max-width: 620px) {
        width: 5vw;
        height: 5vw;
      }
    }
  .emptyNeuronImage {
      width: 30px;
      height: 30px;
      @media (max-width: 620px) {
        width: 6vw;
        height: 6vw;
      }
  }

  h1,h2 { text-transform: uppercase; }

  h2 { 
    color: white;
    margin-bottom: 0.5em;
  }

  .aura {
    animation: pulse 5s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  img {
    margin: auto;
  }
}
</style>

