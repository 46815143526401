function navigateToRepetition(router, repetition) {
  if (repetition.app === 'memomaps-step-group') {
    router.push({
      name: 'StepGroupQuizIntroView',
      params: {
        courseId: repetition.courseId,
        stepGroupId: repetition.list_id,
      },
    });
  }

  if (repetition.app.startsWith('language')) {
    const lang = repetition.app.split("-")[1];
    const id = repetition.list_id;
    router.push(`/memolanguage/${lang}/list/${id}`);
  }
}

export { navigateToRepetition };
