<template>
  <div class="RepetitionsView">

    <div
      @click="goHome()"
      class="--global-clickable">
      <MemolifeLogo class="memolifeLogo" />
    </div>

    <div v-if="noRepetitionsAll" class="noRepetitions">
      <Aura />
      
      <div class="noRepetitionsText"> 
        {{ $t('noRepetitions') }}
      </div>

    </div>


    <div v-if="!noRepetitionsAll" class="hasRepetitions">

      <div class="wrapper">
        <Aura />

        <div
          v-if="recommendedList"
          class="repetitions">

          <h1>{{ $t('next') }}</h1>
        

          <Repetition
            v-for="rep of recommendedList"
            @click.native="gotoRep(rep)"
            :key="rep.id"
            :repetition="rep" />
        </div>

        <div class="header">
          <h2>{{ $t('due') }} ({{ repetitionsDue.length }})</h2>

          <select v-model="filter">

            <option
              :value="null">
              {{ $t('all') }}
            </option>

            <option
              v-for="c in categories"
              :key="c"
              :value="c">
              {{ $t(c) }}
            </option>

          </select>
        </div>

        <div
          v-if="repetitionsDue.length"
          class="repetitions">
          <Repetition
            v-for="repetition of repetitionsDue"
            @click.native="gotoRep(repetition)"
            :key="repetition.id"
            :repetition="repetition" />
        </div>

        <div
          v-if="repetitionsDone.length"
          class="repetitions">
          <h1>{{ $t('done') }} ({{ repetitionsDone.length }})</h1>
          <Repetition
            v-for="repetition of repetitionsDone"
            @click.native="gotoRep(repetition)"
            :key="repetition.id"
            :repetition="repetition" />
        </div>

        <div v-if="noRepetitionsCategory" class="noCategoryRepetitionsText"> 
          {{ $t('noCategoryRepetitions') }}
        </div>

      </div>
    </div>
  </div>
</template>

<translations>

  noRepetitions: Great, you have no repetitions at the moment. Go back to the dashboard and level up your brain in new solar systems (life skills) and galaxies (categories).
  noRepetitions_no: Supert, du har ingen flere repetisjoner. Gå tilbake til dashboardet og spill hjernen i topptrent i nye solsystemer (ferdigheter) og galakser (kategorier).

  noCategoryRepetitions: You have no repetitions in this galaxy.
  noCategoryRepetitions_no: Du har ingen repetisjoner i denne galaksen. 

  next: Recommended
  next_no: Anbefalt

  all: All
  all_no: Alle

  due: All repetitions
  due_no: Alle repetisjoner

  done: Done repetitions
  done_no: Ferdige repetisjoner

  brain: Brain
  brain_no: Hjerne

  knowledge: Knowledge
  knowledge_no: Kunnskap

  mind: Mind
  mind_no: Sinn

  body: Body
  body_no: Kropp

  soul: Soul
  soul_no: Sjel

  career: Career
  career_no: Karriere

  leisure: Leisure
  leisure_no: Fritid

</translations>

<script>
import MemolifeLogo from '@/components/MemolifeLogo';
import Repetition from './Repetition';
import Aura from './Aura';
import { mapGetters, mapActions } from 'vuex';
import categories from '@/categories';
import { navigateToRepetition } from './nav';

export default {
  components: {
    Repetition,
    Aura,
    MemolifeLogo,
  },

  data: function() {
    return {
      filter: null,
    }
  },

  computed: {
    ...mapGetters({
      done: 'repetition/done',
      due: 'repetition/due',
      getJourneyById: 'moduleCourse/getJourneyById',
      getStepById: 'moduleCourse/getStepById',
      getCourseById: 'moduleCourse/getCourseById',
    }),

    categories() {
      return categories.map(c => c.id);
    },

    next() {
      return this.repetitionsDue
        .slice(0, 1)
    },

    recommendedList() {
      var recList = [];
      for (const c of this.categories) {
        const repDue = this.due.filter(this.categoryFilter(c)).slice(0, 1);
        recList.push(...repDue)
      }
      return recList.slice(0, 3);
    },

    repetitionsDue() {
      return this.due
        .filter(this.categoryFilter(this.filter));
    },

    repetitionsDone() {
      return this.done
        .filter(this.categoryFilter(this.filter));
    },

    noRepetitionsAll() {
        return this.due.length === 0;
    },
    noRepetitionsCategory() {
        return this.next.length === 0;
    },
  },

  methods: {
    ...mapActions({ loadRepetitions: 'repetition/load' }),

    gotoRep(repetition) {
      navigateToRepetition(this.$router, repetition);
    },

    isEmpty(cat) {
      return this.due
        .filter(this.categoryFilter(cat))
        .length === 0;
    },

    setFilter(cat) {
      if (!this.isEmpty(cat)) {
        this.filter = cat;
      }
    },

    categoryFilter(cat) {
      if (cat) {
        const c = categories.find(c => c.id === cat);
        return (r) => {
          return c.lifeskillIds.indexOf(r.ls) > -1
        }
      }
      // default is no filtering
      return () => (true);
    },

    goHome() {
      this.$router.push("/");
    },

  },

  async mounted() {
    await this.loadRepetitions();
  },
};
</script>

<style lang="scss" scoped>
.RepetitionsView {
  height: 100%;
  position: absolute;
  background: linear-gradient(180deg, #0a1335 0%, #1e1456 93.87%);
  color: white;
  overflow: scroll;
  padding-left: 1em;
  padding-right: 1em;

  

  .noRepetitions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .noRepetitionsText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 15em;
      width: 30em;
      font-size: 1.2em;
      line-height: 1.6em;
      @media (max-width: 650px) {
        width: 90%;
        font-size: 1em;
      }
    }
  }

  .noCategoryRepetitionsText {
    display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 10em;
      font-size: 1.2em;
      line-height: 1.6em;
  }
  

  .MemolifeLogo {
    height: 3.75em;
    padding-left: 1em;
    align-self: left;
    width: 100vw;
    @media (max-width: 650px) {
      width: 90%;
      padding-left: 0em;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 950px;
    margin: auto;

    select {
      padding: 5px;
      background: rgba(44, 47, 84, 0.23);
      border: none;
      outline: none;
      border-radius: 5px;
      color: rgba(255, 255, 255, 0.705);

      option {
        color: rgba(0, 0, 0, 0.705);
      }
    }
  }


  .energy-level-wrapper {
    h1 {
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      font-weight: 500;
      font-size: 18px;
    }

    p {
      text-align: center;
      color: #4b4d8d;
      font-size: 14px;
    }
  }

  h2 {
    font-size: 18px;
  }

  .repetitions {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;


    h1 {
      font-size: 18px;
    }

    .Repetition {
      padding: 25px;
      margin-top: 15px;
      cursor: pointer;
    }
  }

}
</style>
