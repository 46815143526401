<template>
  <div class="LifeskillNumbershapeWithProgress">
    <img class="sunImage" :src="getSunImage()" alt="" />

    <AnimatedNumbershape :number="lifeskillId" :play="play" :reversed="reversed" color="black" />

    <CircularProgress
      v-if="lifekeyProgress"
      class="lifekeyProgress"
      :progress="lifekeyProgress"
      track-color="rgba(0, 0, 0, 0.1)"
      color="rgba(255, 255, 255, 1)"
      :stroke-width="2"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'
import AnimatedNumbershape from '@/components/AnimatedNumbershape'
import { getCategoryIdFromLifeskillId } from '@/utils'

export default {
  components: { CircularProgress, AnimatedNumbershape },
  inject: ['theme'],
  props: {
    lifeskillId: {
      type: String,
      required: true,
    },
    play: {
      type: Boolean,
      default: false,
    },
    reversed: {
      type: Boolean,
    },
  },
  computed: {
    categoryName() {
      return getCategoryIdFromLifeskillId(this.lifeskillId)
    },
    stats() {
      return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId)
    },
    lifekeyProgress() {
      return this.stats.progress
    },
  },
  methods: {
    getSunImage() {
      if (this.categoryName === 'brain') {
        return require('../UniverseSection/assets/images/sun1.svg')
      } else if (this.categoryName === 'knowledge') {
        return require('../UniverseSection/assets/images/sun3.svg')
      } else if (this.categoryName === 'mind') {
        return require('../UniverseSection/assets/images/sun4.svg')
      } else if (this.categoryName === 'body') {
        return require('../UniverseSection/assets/images/sun2.svg')
      } else if (this.categoryName === 'soul') {
        return require('../UniverseSection/assets/images/sun5.svg')
      } else if (this.categoryName === 'career') {
        return require('../UniverseSection/assets/images/sun6.svg')
      } else if (this.categoryName === 'leisure') {
        return require('../UniverseSection/assets/images/sun7.svg')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.LifeskillNumbershapeWithProgress {
  position: relative;
  border-radius: 50%;
  // background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.3em;
  height: 7.3em;
}

.sunImage {
  width: 100%;
  height: 100%;
}

.AnimatedNumbershape {
  $size: 40%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: $size;
  height: $size;
}

.lifekeyProgress {
  $offset: 0em;
  position: absolute;
  top: -$offset;
  right: -$offset;
  bottom: -$offset;
  left: -$offset;
}
</style>
