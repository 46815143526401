<template>
  <div class="RiveAura">
    <canvas ref="canvas" id="auraCanvas" class="riveContainer" width="170px" height="200px" />
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from './rive/dashboard_aura.riv'

export default {
  props: {
    energyLevel: {
      type: String,
      default: 'green',
    },
  },

  data() {
    return {
      r: null,
      inputs: null,
    };
  },

  mounted() {
    this.setRive();
  },

  computed: {
    auraNum() {
      return {
        'green': 1,
        'yellow': 2,
        'orange': 3,
        'red': 4,
      }[this.energyLevel];
    },
  },

  beforeDestroy(){
   this.r.stop();
   this.r.unsubscribeAll();
   this.r = null;
  },

  watch: {
      auraNum(v){
        if(this.inputs){
          this.inputs[0].value = v;
        }
      }
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: RiveFile,
          canvas: this.$refs.canvas,
          stateMachines: 'aura_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('aura_states');
            this.inputs[0].value = this.auraNum;
          },
      }); 
    },
  },
  
};
</script>

<style scoped lang="scss">

.riveContainer {
  @media (max-width: 620px) {
    width: 35vw;
  }
}

</style>
