import { render, staticRenderFns } from "./Repetition.vue?vue&type=template&id=10e57462&scoped=true&"
import script from "./Repetition.vue?vue&type=script&lang=js&"
export * from "./Repetition.vue?vue&type=script&lang=js&"
import style0 from "./Repetition.vue?vue&type=style&index=0&id=10e57462&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10e57462",
  null
  
)

export default component.exports